import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "gatsby";

import "../../scss/main.scss";

export default function Approach() {
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<link
					rel="shortcut icon"
					type="image/svg"
					href="./assets/marketing-site/images/favicon.svg"
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Our Approach</title>
				<link href="css/main.css" rel="stylesheet" />
			</Helmet>
			<div className="container">
				<Header />
				<main>
					<section className="approach-grid">
						<div className="approach-post-landing">
							<StaticImage placeholder="blurred"
								className="approach-post-left"
								src="../../static/assets/marketing-site/images/our-approach-01.png"
								objectFit='contain'
								loading="eager"
							/>
							<div className="landing-content">
								<p className="secondary">OUR APPROACH</p>
								<br />
								<h1>
									Arranging care is complicated. <br />
									We’re here to help.
								</h1>
								<p className="primary">
									Starting with family care advice to managing complex
									conditions, we’re with you every step of the way.
								</p>
							</div>
							<StaticImage placeholder="blurred"
								className="approach-post-right"
								src="../../static/assets/marketing-site/images/our-approach-02.png"
								objectFit='contain'
								loading="eager"
							/>
						</div>

						<div className="area-input approach">
							<div className="input-field-postcode">
								<div className="postcode-background">
									<div className="postcode-form">
										<p className="secondary">Get started with Edyn Care:</p>
										<div className="post-input-custom">
											<div>
												<input
													className="default"
													type="text"
													id="postalcode"
													name="postcode"
													placeholder="Enter your postcode"
												/>
											</div>
											<div>
												<Link to="/locations">
													<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/searchbtn.svg"
														loading="eager"
													/>
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="postcode-footer">
									<div className="call-us">
										<StaticImage placeholder="blurred"
											src="../../static/assets/marketing-site/images/postcodeimg1.svg"
											alt="advisors-img"
											loading="eager"
										/>
										<div className="call-us-details">
											<p className="secondary">Or call us today on:</p>
											<Link to="tel:020 3970 9900">
												<h4 className="number">020 3970 9900</h4>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="customer-quote">
							<div className="customer-quote-context">
								<div className="customer-img">
									<StaticImage 
										src="../../static/assets/marketing-site/images/customer-img.svg"
										objectFit='contain'
										loading="eager"
										placeholder="blurred"
									/>
								</div>
								<h3>
									“What makes Edyn stand out from other agencies we have used
									for live-in care is the fact that they care - the human touch
									is there. Our communications with them have always centred
									around a basic desire to do the best for my husband as an
									individual, not as a number.”
								</h3>
								<br />
								<div className="customer-names">
									<p className="secondary">
										Bernard S.
										<br />
										Father
									</p>
									<p className="secondary">
										James S.
										<br />
										Son
									</p>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="approach-steps full-view">
							<div className="approach-steps-heading">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/logo-small.png" />
								<h2>
									We work with the whole family to <br />
									get the best outcome for everyone.
								</h2>
								<p className="primary">
									Arranging care can feel overwhelming. Our care advisors are
									here to help you understand live-in care, what is involved and
									how it will work on a day-to-day basis.
								</p>
								<p>Here’s how care with edyn works:</p>
							</div>
							<div className="approach-steps-container">
								<div className="approach-step before-first-step">
									<div className="approach-step-left"> </div>
									<div className="approach-step-center">
										<span></span>
									</div>
									<div className="approach-step-right"> </div>
								</div>
								<div className="approach-step">
									<div className="approach-step-left">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-1.png" />
									</div>
									<div className="approach-step-center">
										<span>1</span>
									</div>
									<div className="approach-step-right">
										<h3>Getting to know you: </h3>
										<p className="primary">
											Your journey begins with a care consultation with one of
											our family care advisors and then a no-obligation care
											assessment from a care manager. They will learn what
											you’re hoping to achieve from your care, ensure that your
											property is safe and will share a digital care plan of
											your goals.
										</p>
										<button className="btn-primary">
											Book your free care consultation
										</button>
									</div>
								</div>
								<div className="approach-step">
									<div className="approach-step-left">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-2.png" />
									</div>
									<div className="approach-step-center">
										<span>2</span>
									</div>
									<div className="approach-step-right">
										<h3>Matching your care team: </h3>
										<p className="primary">
											When you’re ready, we’ll send a tailored list of potential
											carers to you. We use smart technology to select carers
											based on experience, interests and location. Each carer
											has their own digital CV - you'll be able to see their
											care experience, their training, a biography and what
											they're interested in.
										</p>
										<div className="approach-steps-checklist">
											<div className="ap-checklist">
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/approach-check.png"
												/>
												<p className="secondary">Trained By Us</p>
											</div>
											<div className="ap-checklist">
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/approach-check.png"
												/>
												<p className="secondary">Insured</p>
											</div>
											<div className="ap-checklist">
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/approach-check.png"
												/>
												<p className="secondary">DBS Check</p>
											</div>
										</div>
									</div>
								</div>
								<div className="approach-step">
									<div className="approach-step-left">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-3.png" />
									</div>
									<div className="approach-step-center">
										<span>3</span>
									</div>
									<div className="approach-step-right">
										<h3>Carer introduction: </h3>
										<p className="primary">
											Unlike other care companies, we believe in finding the
											right match which is why and we facilitate introductory
											calls for our carers and clients, so they can get to know
											each other before any care starts.
										</p>
										<div className="ap-video-link">
												<Link to="#">
													<button class="btn-primary"><StaticImage placeholder="blurred" class="icon-default" src="../../static/assets/marketing-site/images/approach-play.png" />Meet one of our carers</button>
												</Link>
											</div>
									</div>
								</div>
								<div className="approach-step">
									<div className="approach-step-left">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-4.png" />
									</div>
									<div className="approach-step-center">
										<span>4</span>
									</div>
									<div className="approach-step-right">
										<h3>Carer arrives:</h3>
										<p className="primary">
											We’ll arrange travel with the carer. They’ll arrive having
											read your care plan and understand your care requirements.
											Your family care coordinator will follow up that day to
											ensure everything is order.
										</p>
									</div>
								</div>
								<div className="approach-step">
									<div className="approach-step-left">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-5.png" />
									</div>
									<div className="approach-step-center">
										<span>5</span>
									</div>
									<div className="approach-step-right">
										<h3>We manage your care: </h3>
										<p className="primary">
											Your dedicated care team will handle the progression of
											your care, liaising with your carers and family members to
											ensure all parties are aligned and working together.
										</p>
									</div>
								</div>
								<div className="approach-step">
									<div className="approach-step-left">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-6.png" />
									</div>
									<div className="approach-step-center">
										<span>6</span>
									</div>
									<div className="approach-step-right">
										<h3>Your smart care: </h3>
										<p className="primary">
											Stay informed during your care with my edyn - a smart
											online portal which puts all your care information in one
											place. From updating payment details to reading the care
											plan and carer notes, we keep everyone updated and
											informed.
										</p>
									</div>
								</div>
								<h3 className="last-step-text">And relax…</h3>
							</div>
						</div>
					</section>

					<section>
						<div className="approach-steps mobile">
							<div className="approach-steps-heading">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/logo-small.png" />
								<h2>
									We work with the whole family to <br />
									get the best outcome for everyone.
								</h2>
								<p className="primary">
									Arranging care can feel overwhelming. Our care advisors are
									here to help you understand live-in care, what is involved and
									how it will work on a day-to-day basis.
								</p>
								<p>Here’s how care with edyn works:</p>
							</div>

							<div className="approach-steps-container">
								<div className="approach-timeline-steps-mobile">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-timeline-points.png" objectFit="contain"/>
								</div>

								<div className="approach-timeline-information">
									<div className="information-section one">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-1.png" />
										<h3>Getting to know you: </h3>
										<p className="primary">
											Your journey begins with a care consultation with one of
											our family care advisors and then a no-obligation care
											assessment from a care manager. They will learn what
											you’re hoping to achieve from your care, ensure that your
											property is safe and will share a digital care plan of
											your goals.
										</p>
										<button className="btn-primary">
											Book your free care consultation
										</button>
									</div>

									<div className="information-section two">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-2.png" />
										<h3>Matching your care team: </h3>
										<p className="primary">
											When you’re ready, we’ll send a tailored list of potential
											carers to you. We use smart technology to select carers
											based on experience, interests and location. Each carer
											has their own digital CV - you'll be able to see their
											care experience, their training, a biography and what
											they're interested in.
										</p>
										<div className="approach-steps-checklist">
											<div className="ap-checklist">
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/approach-check.png"
													objectFit="contain"
												/>
												<p className="secondary">Trained By Us</p>
											</div>
											<div className="ap-checklist">
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/approach-check.png"
													objectFit="contain"
												/>
												<p className="secondary">Insured</p>
											</div>
											<div className="ap-checklist">
												<StaticImage placeholder="blurred"
													className="icon-default"
													src="../../static/assets/marketing-site/images/approach-check.png"
													objectFit="contain"
												/>
												<p className="secondary">DBS Check</p>
											</div>
										</div>
									</div>

									<div className="information-section three">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-3.png" />
										<div className="approach-step-right">
											<h3>Carer introduction: </h3>
											<p className="primary">
												Unlike other care companies, we believe in finding the
												right match which is why and we facilitate introductory
												calls for our carers and clients, so they can get to know
												each other before any care starts.
											</p>
											<div className="ap-video-link">
												<Link to="#">
													<button class="btn-primary"><StaticImage placeholder="blurred" class="icon-default" src="../../static/assets/marketing-site/images/approach-play.png" />Meet one of our carers</button>
												</Link>
											</div>
										</div>
									</div>

									<div className="information-section four">
											<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-4.png" />
										<div className="approach-step-right">
											<h3>Carer arrives:</h3>
											<p className="primary">
												We’ll arrange travel with the carer. They’ll arrive having
												read your care plan and understand your care requirements.
												Your family care coordinator will follow up that day to
												ensure everything is order.
											</p>
										</div>
									</div>

									<div className="information-section five">
											<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-img-5.png" />
										<div className="approach-step-right">
											<h3>We manage your care: </h3>
											<p className="primary">
												Your dedicated care team will handle the progression of
												your care, liaising with your carers and family members to
												ensure all parties are aligned and working together.
											</p>
										</div>
									</div>

									<div className="information-section six">
											<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-steps-image-6-mobile.png" />
										<div className="approach-step-right">
											<h3>Your smart care: </h3>
											<p className="primary">
												Stay informed during your care with my edyn - a smart
												online portal which puts all your care information in one
												place. From updating payment details to reading the care
												plan and carer notes, we keep everyone updated and
												informed.
											</p>
										</div>
									</div>

									<h3 className="last-step-text">And relax…</h3>
								</div>

							</div>
						</div>
		
					</section>

					<section>
						<div className="approach-care">
							<div className="care-context">
								<h1>Exceptional care is not an individual job</h1>
								<p className="primary">
									It takes a team working together to deliver our clients truly
									exceptional care. A Care Team consists of a group of
									specialists committed to the key areas of our client’s
									support.
								</p>
								<Link to="" className="button-link">
									<button className="btn-primary">Meet our carers</button>
								</Link>
							</div>
							<div className="care-takers">
								<p className="secondary heading">Our care teams consist of: </p>
								<div className="care-takes-individual-group">
									<div className="care-takes-individual">
										<br />
										<StaticImage placeholder="blurred" class="care-taker-desktop" src="../../static/assets/marketing-site/images/caretaker-img-01.svg" objectFit="contain"/>
										<StaticImage placeholder="blurred" class="care-taker-mobile" src="../../static/assets/marketing-site/images/caretaker-img-01-mobile.png" objectFit="contain"/>
										<h3>A main and respite carer</h3>
										<br />
										<p className="secondary pro">
											They provide day-to-day support and help in your home.{" "}
										</p>
										<br />
									</div>
									<div className="care-takes-individual">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/caretaker-img-02.svg" objectFit="contain"/>
										<h3>Family care coordinator</h3>
										<br />
										<p className="secondary pro">
											They will ensure you have the best carers matched to you
											providing the consistent help day in day out.
										</p>
									</div>
									<div className="care-takes-individual">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/caretaker-img-03.svg" objectFit="contain"/>
										<h3>Family Care Manager</h3>
										<br />
										<p className="secondary pro">
											They are here to ensure the care and support we provide is
											aligned to your desired outcomes and changing needs.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="approach-consistent">
							<div className="approach-consistent-heading">
								<div className="approach-consistent-heading-left">
									<h1>We understand the importance of consistency</h1>
								</div>
								<div className="approach-consistent-heading-right">
									<p className="primary">
										For each of our clients we allocate two carers: Primary
										carer and Respite carer. They work in tandem to give you and
										your family the consistency you deserve.{" "}
									</p>
									<p className="primary">
										Our professional carers may be amazing, but they are only
										human. So as well as two hours breaks taken at a suitable
										time throughout the day, your primary live-in carer will
										also need to take time off.
									</p>
								</div>
							</div>
							<div className="approach-consistent-schedule">
								<div className="schedule-heading">
									<div className="schedule-heading-left">
										<h2>An example schedule</h2>	
									</div>
									<div className="schedule-heading-right">
										<h2>Your carers:</h2>
										<div className="carer-heading carer">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/carer-olivia.png"
												alt=""
											/>
											<p>
												Main carer: <br />
												Olivia
											</p>
										</div>
										<div className="carer-heading carer green">
											<StaticImage placeholder="blurred"
												src="../../static/assets/marketing-site/images/carer-lyn.png"
												alt=""
											/>
											<p>
												Respite Carer: <br />
												Lyn
											</p>
										</div>
									</div>
								</div>
								<div className="approach-schedule-container">
									<div className="approach-schedule-left">
										<div className="approach-week week-trial">
											<p className="full-view">Trial Week</p>
											<p className="mobile-only">Week 1 (Trial)</p>
											<p>
												Your first week is a completely risk-free trial and
												Oliva, main carer, moves in.
											</p>
										</div>
										<div className="approach-week week-2-3">
											<p>Week 2-3</p>
											<p>If you’re happy, Olivia continues.</p>
										</div>
										<div className="approach-week week-4">
											<p>Week 4</p>
											<p>
												Olivia, your main carer, takes time off and your respite
												carer Lyn takes over
											</p>
										</div>
										<div className="approach-week week-5-7">
											<p>Weeks 5-7</p>
											<p>
												Olivia returns. They’ll typically be in place for
												another three weeks.
											</p>
										</div>
									</div>
									<div className="approach-schedule-right">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/approach-infographic.png" alt="" /> 
									</div>
								</div>
							</div>
						</div>
					</section>
					<hr />

					<section>
						<div className="approach-tech">
							<div className="approach-tech-context">
								<h2>Technology to make us more human</h2>
								<br />
								<p className="primary">
									Edyn is one of the few live-in care companies with a dedicated
									software engineering team working on building our own software
									applications to enhance our client’s care directly.{" "}
								</p>
								<br />
								<p className="primary">
									Our portal gives our carers and families amazing visibility.
									Simple and transparent information on schedule, care plan,
									risk assessments, notes, wellness and payments.
								</p>
								<br />

								<div className="approach-tech-categories">
									<div className="approach-tech-topic">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/group-tech.svg" />
										<p className="secondary">Your care team</p>
									</div>
									<div className="approach-tech-topic">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/calendarday-tech.svg" />
										<p className="secondary">Visit summary</p>
									</div>
									<div className="approach-tech-topic green">
										<StaticImage placeholder="blurred"
											className="green"
											src="../../static/assets/marketing-site/images/piechart-green.svg"
										/>
										<p className="secondary">Wellness report</p>
									</div>
									<div className="approach-tech-topic">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/text-doc-tech.svg" />
										<p className="secondary">Regular care statements</p>
									</div>
								</div>
							</div>
							<div className="approach-img">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/tech-image-01.jpg" />
							</div>
						</div>
						<hr />
					</section>

					<section className="info-block-pricing-page approach">
						<div className="paying-for-care">
							<div className="info-block-section center">
								<h2>More about how Edyn Care works</h2>
								<p class="sub-heading">Read our FAQs</p>
								<div className="info-block-content">
									<div className="infoblock read-more yellow">
										<h3>What happens in my care consulattion?</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
									<div className="infoblock read-more yellow">
										<h3>What happens in my care assessment?</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
									<div className="infoblock read-more yellow">
										<h3>What is a fully managed agency?</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
			</div>
			<Footer />
		</>
	);
}
